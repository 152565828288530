import axios from 'axios';

const getAttribute = (selector, attribute) => {
    const element = window.document.querySelector(selector);
    return element ? element.getAttribute(attribute) : null;
};

const findSellerId = async () => {
    const api = axios.create({ baseURL: window.xola.path });

    // Try to find the ID from the experience buttons.
    let sellerId = getAttribute('[data-seller]', 'data-seller');

    if (!sellerId) {
        const buttonId = getAttribute('[data-button-id]', 'data-button-id');
        if (buttonId) {
            const { data: button } = await api.get(`/api/buttons/${buttonId}`);
            return button.seller.id;
        }
    }

    if (!sellerId) {
        const experienceId = getAttribute('[data-experience]', 'data-experience');
        if (experienceId) {
            const { data: experience } = await api.get(`/api/experiences/${experienceId}`);
            return experience.seller.id;
        }
    }

    return sellerId;
};

export default findSellerId;
