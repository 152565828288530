import findSellerId from './findSellerId';
import addDays from 'date-fns/add_days';
import isPast from 'date-fns/is_past';
import format from 'date-fns/format';
import config from '../config.json';
import Module from 'xolabot-sdk';
import api from './api';

const formatTimeSlot = (event) => {
    const date = format(event.start, 'MMM Do');
    const time = format(event.start, 'h:mm A');
    return date + ' at ' + time + ' with ' + event.open + ' openings left';
};

const STAGE_PROMPT = 100;
const STAGE_SELECT_EXPERIENCE = 200;
const STAGE_SELECT_GUEST_COUNT = 300;
const STAGE_SELECT_TIME_SLOT = 400;

const MAX_GUEST_COUNT_BUTTONS = 8;
const MAX_TIME_SLOT_BUTTONS = 4;

const VERSION = 2;

class LightningDealsModule extends Module {
    static APP_ID = config.plugin.id;
    couponApplied = false;
    purchased = false;

    constructor(options) {
        super('lightning_deals', options);

        if (this.state.version !== VERSION) {
            this.clearState();
            this.setState({ version: VERSION });
        }

        // Listen to Checkout button click event and then stop the init method.
        // We don't want to show lightning deals to travelers already interested in purchase.
        this.bus.on({ channel: this.channels.WEBSITE_COLLECTOR, type: 'xola_button_click' }, () => {
            clearTimeout(this.initTimeout);
        });

        this.bus.on({ channel: this.channels.CHECKOUT, type: 'closed' }, this.handleCheckoutClosed);
        this.bus.on({ channel: this.channels.CHECKOUT_COLLECTOR, type: 'order_info' }, this.detectPurchase);

        // Listen to order info event and then apply the coupon.
        this.stopApplyCoupon = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: 'order_info' },
            this.applyCoupon,
        );

        // Module events.
        this.bus.on({ channel: this.channel, type: 'open_checkout' }, this.openCheckout);
        this.bus.on({ channel: this.channel, type: 'interested' }, this.handleInterested);
        this.bus.on({ channel: this.channel, type: 'dropped' }, this.handleDropped);

        // Init.
        if (this.state.sellerId && this.state.deal) {
            this.restoreState();
        } else {
            if (this.state.session !== this.getSession()) {
                this.initTimeout = setTimeout(this.init, this.config.delay * 1000);
            }
        }
    }

    shouldInit() {
        return !this.state.resumeAfter || isPast(this.state.resumeAfter);
    }

    openCheckout = () => {
        if (this.openCheckoutSync) {
            this.openCheckoutSync();
        } else {
            console.warn('Method for opening checkout is not registered!');
        }
    };

    safeDrop() {
        this.setState({ resumeAfter: addDays(new Date(), 1) });
        this.drop && this.drop();
    }

    handleCheckoutClosed = () => {
        setTimeout(() => {
            if (this.couponApplied && !this.purchased) {
                this.safeDrop();
                this.couponApplied = false;
            }
        }, 1000);
    };

    detectPurchase = ({ purchased }) => {
        if (this.couponApplied && purchased) {
            this.purchased = true;
        }
    };

    async getSellerId() {
        if (this.state.sellerId) {
            return this.state.sellerId;
        }
        const sellerId = await findSellerId();
        this.setState({ sellerId });
        return sellerId;
    }

    restoreState() {
        const { stage } = this.state;

        if (stage === STAGE_SELECT_EXPERIENCE) {
            this.registerSelectExperienceListeners();
        } else if (stage === STAGE_SELECT_GUEST_COUNT) {
            this.registerGuestCountListeners();
        } else if (stage === STAGE_SELECT_TIME_SLOT) {
            this.registerTimeSlotListeners();
        }
    }

    getSession() {
        return window.sessionStorage.getItem('xb_session_id');
    }

    applyCoupon = ({ reached_payment_page, arrival_date, arrival_time }) => {
        const { deal = {} } = this.state;
        const { events = [] } = deal;

        const isTimeSlotValid = events.some((event) => {
            const arrivalDate = format(event.start, 'YYYY-MM-DD');
            const arrivalTime = format(event.start, 'HHmm');
            return arrivalDate == arrival_date && arrivalTime == arrival_time;
        });

        if (reached_payment_page && isTimeSlotValid) {
            const { coupon } = this.state;
            this.checkout.xwm.checkout.send('apply_coupon', coupon.code);
            this.couponApplied = true;

            this.setState({
                deal: null,
                event: null,
                coupon: null,
                session: this.getSession(),
            });

            this.stopApplyCoupon();
        }
    };

    registerTimeSlotListeners() {
        const { deal, experience } = this.state;

        if (deal) {
            deal.events
                .filter((event) => event.experience.id === experience.id)
                .forEach((event) => {
                    this.bus.on({ channel: this.channel, type: event.start }, () => {
                        this.handleEventClick(event);
                    });
                });
        }
    }

    registerGuestCountListeners() {
        for (let guestCount = 1, maxGuestCount = this.getMaxGuestCount(); guestCount <= maxGuestCount; guestCount++) {
            this.bus.on({ channel: this.channel, type: guestCount }, () => {
                this.handleGuestCountClick(guestCount);
            });
        }
    }

    registerSelectExperienceListeners() {
        const { deal } = this.state;

        if (deal) {
            deal.experiences.forEach((experience) => {
                this.bus.on({ channel: this.channel, type: experience.id }, () => {
                    this.handleExperienceClick(experience);
                });
            });
        }
    }

    init = async () => {
        if (!this.shouldInit()) {
            return;
        }

        const [deal] = (await api.get('/deals', { params: { seller: await this.getSellerId() } })).data;

        if (!deal) {
            return;
        }

        this.setState({ deal });

        const notification = {
            message: 'Hi. We have some last-minute lightning ⚡deals. Are you interested in learning more?',
            buttons: [
                { label: 'Yes, please', event: { channel: this.channel, type: 'interested' }, primary: true },
                { label: 'No, thanks', event: { channel: this.channel, type: 'dropped' } },
            ],
            prompt: true,
        };

        await this.notify({ confidence: 0.5, value: 1 }, notification);
        this.setState({ stage: STAGE_PROMPT });
    };

    hideChatButtons() {
        this.bus.emit({ channel: this.channels.UI, type: 'hide_chat_buttons' });
        this.bus.emit({ channel: this.channels.UI, type: 'hide_chat_medias' });
        this.bus.emit({ channel: this.channels.UI, type: 'read_chat_messages' });
    }

    getMaxGuestCount() {
        const { deal, experience } = this.state;

        const maxGuestCount = deal.events.reduce((max, event) => {
            if (event.experience.id === experience.id) {
                return max > event.open ? max : event.open;
            }

            return max;
        }, 0);

        return Math.min(maxGuestCount, MAX_GUEST_COUNT_BUTTONS);
    }

    getDiscountString(deal) {
        const algorithm = deal.discount.algorithm === 'percent' ? '?%' : '$?';
        const discount = algorithm.replace('?', deal.discount.amount);
        const type = deal.discount.type === 'demographic' ? 'per person' : 'your booking';
        return `${discount} off ${type}`;
    }

    handleInterested = async () => {
        this.hideChatButtons();
        this.addUserResponse('Yes, please');
        this.bus.emit({ channel: this.channels.UI, type: 'open_chat' });
        await this.sleep(500);

        const { deal } = this.state;
        const description = this.getDiscountString(deal);

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            {
                body: `Fantastic! We may be able to offer you up to ${description} for a last-minute lightning deal depending on how many people are in your party. Which experience are you interested in?`,
                medias: deal.experiences.map((experience) => ({
                    id: experience.id,
                    title: experience.name,
                    subtitle: experience.excerpt,
                    image: `${config.xola.url}/api/experiences/${experience.id}/medias/default?size=small`,
                    event: { channel: this.channel, type: experience.id },
                })),
            },
        );

        this.registerSelectExperienceListeners();
        this.setState({ stage: STAGE_SELECT_EXPERIENCE });
    };

    handleExperienceClick = (experience) => {
        this.setState({ experience });
        this.addUserResponse(experience.name);
        this.addBotResponse(`Great, you’ve selected ${experience.name}`);
        const buttons = [];

        for (
            let guestCount = experience.group.orderMin || 1, maxGuestCount = this.getMaxGuestCount();
            guestCount <= maxGuestCount;
            guestCount++
        ) {
            buttons.push({
                label: guestCount === MAX_GUEST_COUNT_BUTTONS ? guestCount + '+' : guestCount,
                event: { channel: this.channel, type: guestCount },
                primary: true,
            });
        }

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: 'How many people are in your party?', buttons },
        );

        this.setState({ stage: STAGE_SELECT_GUEST_COUNT });
        this.registerGuestCountListeners();
    };

    handleGuestCountClick = async (guestCount) => {
        this.guestCount = guestCount;
        this.hideChatButtons();
        this.addUserResponse(guestCount);
        await this.sleep(500);

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            {
                body: `Thanks! Based on your party size of ${guestCount} we can offer you the following one-time only ⚡ deals. Please select which reservation time works best for you.`,
                buttons: this.state.deal.events
                    .filter((event) => event.open >= guestCount && event.experience.id === this.state.experience.id)
                    .slice(0, MAX_TIME_SLOT_BUTTONS)
                    .map((event) => {
                        return {
                            label: formatTimeSlot(event),
                            event: { channel: this.channel, type: event.start },
                            primary: true,
                        };
                    }),
            },
        );

        this.setState({ stage: STAGE_SELECT_TIME_SLOT });
        this.registerTimeSlotListeners();
    };

    handleDropped = async () => {
        this.clearState();
        this.hideChatButtons();

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: 'No, thanks', read: true, user: true },
        );

        await this.sleep(500);

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: 'Ok, have a nice day!', read: true },
        );

        this.safeDrop();
    };

    addUserResponse(message) {
        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: message, read: true, user: true },
        );
    }

    addBotResponse(message) {
        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: message, read: true, user: false },
        );
    }

    async handleEventClick(event) {
        const { experience } = this.state;
        this.setState({ event });
        this.hideChatButtons();

        if (isPast(event.start)) {
            this.bus.emit(
                { channel: this.channels.UI, type: 'add_chat_message' },
                { body: 'Sorry, this deal is no longer available. We will notify you when a deal is available again.' },
            );

            this.clearState();
            return;
        }

        this.setState({ event });

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            { body: 'Yes, book now', read: true, user: true },
        );

        await this.sleep(500);

        this.bus.emit(
            { channel: this.channels.UI, type: 'add_chat_message' },
            {
                body: this.checkout.isMobile
                    ? 'Great! Please wait while we create a special coupon for you. Checkout will be ready soon...'
                    : 'Great! Please wait while we create a special coupon for you. Checkout will be opened automatically...',
            },
        );

        const { data: coupons } = await api.get(`/deals/${format(event.start, 'YYMMDDHHmm')}/coupons`, {
            params: { seller: await this.getSellerId(), experience: experience.id },
        });

        const [coupon] = coupons;
        this.setState({ coupon });

        this.openCheckoutSync = () => {
            this.checkout.checkout(
                { seller: this.state.sellerId, experience: this.state.experience.id },
                {
                    arrival: format(event.start, 'YYYY-MM-DD'),
                    arrivalTime: format(event.start, 'HHmm'),
                    quantity: this.guestCount,
                },
            );

            this.sleep(3000).then(() => {
                this.hideChatButtons();
            });
        };

        // Opening a checkout in an async function will be blocked by the browser on mobile.
        // For mobile devices, we need to show a button for opening the checkout synchronously.
        if (this.checkout.isMobile) {
            this.bus.emit(
                { channel: this.channels.UI, type: 'add_chat_message' },
                {
                    body: "We've created a special coupon code just for you. Please open checkout to use your ⚡️ deal",
                    buttons: [
                        {
                            label: 'Open Checkout',
                            event: { channel: this.channel, type: 'open_checkout' },
                            primary: true,
                        },
                    ],
                },
            );
        } else {
            this.openCheckoutSync();
        }
    }
}

LightningDealsModule.load();
